import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_upgrade_plan",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-xl" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-header justify-content-end border-0 pb-0" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body pt-0 pb-15 px-5 px-xl-20" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = {
  class: "nav-group nav-group-outline mx-auto",
  "data-kt-buttons": "true"
}
const _hoisted_10 = { class: "row mt-10" }
const _hoisted_11 = { class: "col-lg-6 mb-10 mb-lg-0" }
const _hoisted_12 = { class: "nav flex-column" }
const _hoisted_13 = ["onClick", "data-bs-target"]
const _hoisted_14 = { class: "d-flex align-items-center me-2" }
const _hoisted_15 = { class: "form-check form-check-custom form-check-solid form-check-success me-6" }
const _hoisted_16 = ["value", "checked"]
const _hoisted_17 = { class: "flex-grow-1" }
const _hoisted_18 = { class: "d-flex align-items-center fs-2 fw-bolder flex-wrap" }
const _hoisted_19 = {
  key: 0,
  class: "badge badge-light-success ms-2 fs-7"
}
const _hoisted_20 = { class: "fw-bold opacity-50" }
const _hoisted_21 = { class: "ms-5" }
const _hoisted_22 = {
  key: 0,
  class: "btn btn-sm btn-primary"
}
const _hoisted_23 = { class: "fs-3x fw-bolder" }
const _hoisted_24 = { class: "col-lg-6" }
const _hoisted_25 = { class: "tab-content rounded h-100 bg-light p-10" }
const _hoisted_26 = ["id"]
const _hoisted_27 = { class: "pb-5" }
const _hoisted_28 = { class: "text-gray-400 fw-bold" }
const _hoisted_29 = { class: "pt-1" }
const _hoisted_30 = { class: "fw-bold fs-5 text-gray-700 flex-grow-1" }
const _hoisted_31 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_32 = { class: "fw-bold fs-5 text-gray-400 flex-grow-1" }
const _hoisted_33 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mb-13 text-center" }, [
            _createElementVNode("h1", { class: "mb-3" }, "Upgrade a Plan"),
            _createElementVNode("div", { class: "text-gray-400 fw-bold fs-5" }, [
              _createTextVNode(" If you need more info, please check "),
              _createElementVNode("a", {
                href: "#",
                class: "link-primary fw-bolder"
              }, "Pricing Guidelines"),
              _createTextVNode(". ")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.current = 'month')),
                class: _normalizeClass([[_ctx.current === 'month' && 'active'], "btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2"])
              }, " Monthly ", 2),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.current = 'annual')),
                class: _normalizeClass([[_ctx.current === 'annual' && 'active'], "btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"])
              }, " Annual ", 2)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      onClick: ($event: any) => (_ctx.selected = plan.title),
                      class: _normalizeClass(["nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6", [
                        index !== _ctx.plans.length - 1 && 'mb-6',
                        plan.default && 'active',
                        !plan.custom && 'btn-active btn-active-primary',
                      ]]),
                      "data-bs-toggle": "tab",
                      "data-bs-target": `#kt_upgrade_plan_${index}`
                    }, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            name: "plan",
                            value: plan.title,
                            checked: _ctx.selected === plan.title
                          }, null, 8, _hoisted_16)
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("h2", _hoisted_18, [
                            _createTextVNode(_toDisplayString(plan.title) + " ", 1),
                            (plan.label)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(plan.label), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_20, _toDisplayString(plan.subTitle), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        (plan.custom)
                          ? (_openBlock(), _createElementBlock("button", _hoisted_22, " Contact Us "))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "mb-2" }, "$", -1)),
                              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.current === "month"
                                ? plan.priceMonth
                                : plan.priceAnnual), 1),
                              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "fs-7 opacity-50" }, [
                                _createTextVNode("/ "),
                                _createElementVNode("span", { "data-kt-element": "period" }, "Mon")
                              ], -1))
                            ], 64))
                      ])
                    ], 10, _hoisted_13))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (!plan.custom)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass([[plan.default && 'show active'], "tab-pane fade"]),
                            id: `kt_upgrade_plan_${index}`
                          }, [
                            _createElementVNode("div", _hoisted_27, [
                              _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "fw-bolder text-dark" }, " What’s in Startup Plan? ", -1)),
                              _createElementVNode("div", _hoisted_28, _toDisplayString(plan.description), 1)
                            ]),
                            _createElementVNode("div", _hoisted_29, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plan.features, (feature, i) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: i,
                                  class: _normalizeClass([[i !== plan.features - 1 && 'mb-7'], "d-flex align-items-center"])
                                }, [
                                  (feature.supported)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("span", _hoisted_30, _toDisplayString(feature.title), 1),
                                        _createElementVNode("span", _hoisted_31, [
                                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen043.svg" })
                                        ])
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("span", _hoisted_32, _toDisplayString(feature.title), 1),
                                        _createElementVNode("span", _hoisted_33, [
                                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen040.svg" })
                                        ])
                                      ], 64))
                                ], 2))
                              }), 128))
                            ])
                          ], 10, _hoisted_26))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ])
            ])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-flex flex-center flex-row-fluid pt-12" }, [
            _createElementVNode("button", {
              type: "reset",
              class: "btn btn-white me-3",
              "data-bs-dismiss": "modal"
            }, " Cancel "),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary"
            }, "Upgrade Plan")
          ], -1))
        ])
      ])
    ])
  ]))
}